export enum Language {
  en = 'en',
  nl = 'nl',
  fr = 'fr',
  de = 'de',
};

export type Video = {
  pk: number;

  title_en: string;
  title_nl: string;

  placeholder_image: string;
  
  url_en: string;
  url_nl: string;
  url_fr: string;
  url_de: string;

  url_en_subs: string;
  url_nl_subs: string;
  url_fr_subs: string;
  url_de_subs: string;
  
  featured: boolean;
  by_alamire: boolean;
  available_date_time: null | string;
};

export type DocuSeries = {

  id: string;
  order: number;
  hidden: boolean;

  title_en: string;
  title_nl: string;

  information_en: string;
  information_nl: string;

  videos: {video: Video}[];
};

export type Code = {
  code: string;
  expired: boolean;
}

export type VideoResponse = {
  featured_video: Video;
  docu_series: DocuSeries[];
  code: string;
  codes: Code[];
};

export type LoginResponse = {
  result: {
    success: boolean,
    preview: boolean
  }
};

export type CookieData = {
  address: string | null;
  preview: string | null;
  isNewUser?: boolean;
};
