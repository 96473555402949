import React from 'react';
import './App.css';

import { VideoProvider } from './providers/VideoContext';
import VideoScreen from './components/VideoScreen';

import PiwikPro from '@piwikpro/react-piwik-pro';

PiwikPro.initialize('162ffd6b-9155-4ebb-882c-8be6f9e86620', 'https://thinkmobile.containers.piwik.pro');

const App: React.FC = () => {
  return (
    <div style={{backgroundColor: 'black'}}>
      <VideoProvider>
        <VideoScreen />
      </VideoProvider>
    </div>
  );
}

export default App;
