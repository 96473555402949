import React from 'react';

import { Language } from '../domain/types';

interface Props {
  currentLang: Language;
  setCurrentLang: (lang: Language) => void;
  hasGerman: boolean;
}

const LanguageSelector: React.FC<Props> = ({ currentLang, setCurrentLang, hasGerman }) => {

  const [hoveredLang, setHoveredLang] = React.useState<Language | null>(null);

  return (
    <span style={{display: 'flex', marginRight: 50, marginLeft: 50, marginBottom: 0, justifyContent: 'flex-end'}}>
      {Object.values(Language).map((value) => {

        if (value === 'de' && !hasGerman) {
          return null;
        }

        const isActive = currentLang === value;
        const isHovered = hoveredLang === value;

        return (
          <h4 
            key={value}
            onClick={() => setCurrentLang(value)}
            style={{
              marginRight: 16,
              fontSize: '1.5rem',
              cursor: 'pointer',
              marginTop: 4,
              marginBottom: 4,
            }}
            onMouseEnter={() => setHoveredLang(value)}
            onMouseLeave={() => setHoveredLang(null)}
          >
            <span
              style={{
                color: !isActive && isHovered ? '#DB2600'  : 'white',
                opacity: isActive ? 1 : 0.5,
              }}
            >
              {value.toUpperCase()}
            </span>
          </h4>
        );
      })}
    </span>
  );
};

export default LanguageSelector;