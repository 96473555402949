import React, {useRef, useState, useEffect, useCallback} from 'react';

import { Language, Video } from '../domain/types';

import { CustomEvent } from '@piwikpro/react-piwik-pro';
import { useVideos } from '../providers/VideoContext';

const CLOUDFRONT_DOMAIN = 'https://djud8rv6tyrfq.cloudfront.net/';
const S3_DOMAIN = 'https://alamire.s3.amazonaws.com/';
const S3_DOMAIN_2 = 'https://alamire.s3.eu-west-3.amazonaws.com/';

interface Props {
  video: Video;
  lang: Language;
  email: string;
}

const FeaturedVideo: React.FC<Props> = ({
  video,
  lang,
  email,
}) => {

  const { isNewUser, setIsAuthenticated } = useVideos();

  let ref = useRef<HTMLVideoElement | null>(null);
  
  const [videoReady, setVideoReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [src, setSrc] = useState(video.url_en);

  const convertToCloudFront = (src: string) => {
    if (src.startsWith(S3_DOMAIN)) {
      const path = src.split(S3_DOMAIN)[1];
      return `${CLOUDFRONT_DOMAIN}${path}`;

    } else if (src.startsWith(S3_DOMAIN_2)) {
      const path = src.split(S3_DOMAIN_2)[1];
      return `${CLOUDFRONT_DOMAIN}${path}`;
    }

    return src;
  };

  const getSource = useCallback(() => {
    switch (lang) {
      case 'en':
        return video.url_en;

      case 'nl':
        return video.url_nl;

      case 'fr':
        return video.url_fr;

      case 'de':
        return video.url_de;

      default:
        return video.url_en;
    };
  }, [video, lang]);

  useEffect(() => {
    setSrc(convertToCloudFront(getSource()));
  }, [video, lang, getSource]);

  // const play = useCallback(async () => {
  //   try {
  //     await ref.current?.play();
  //   } catch (error) {
  //     console.warn("play failed", error);
  //   }

  //   // @ts-ignore
  //   if (ref.current?.requestFullscreen && !ref.current?.webkitEnterFullscreen) {
  //     await ref.current?.requestFullscreen();
  //   // @ts-ignore
  //   } else if (ref.current?.webkitEnterFullscreen) {
  //     try {
  //       // @ts-ignore
  //       console.log("webkitEnterFullscreen - do nothing");
  //       // await ref.current?.webkitEnterFullscreen();
  //     } catch (error) {
  //       console.warn("webkitEnterFullscreen failed", error);
  //     }
  //   // @ts-ignore
  //   } else if (ref.current?.webkitRequestFullscreen) {
  //   // @ts-ignore
  //     await ref.current?.webkitRequestFullscreen();
  //   // @ts-ignore
  //   } else if (ref.current?.mozRequestFullScreen) {
  //   // @ts-ignore
  //     await ref.current?.mozRequestFullScreen();
  //   // @ts-ignore
  //   } else if (ref.current?.msRequestFullscreen) {
  //   // @ts-ignore
  //     await ref.current?.msRequestFullscreen();
  //   }
  // }, []);

  const onVideoPlay = () => {
    setIsPlaying(true);
    try {
      CustomEvent.trackEvent('alamiretv', 'video-play', src.split('/').pop());
      CustomEvent.trackEvent('alamiretv', 'video-watched-by', email);

    } catch (error) {
      // ignore error
    }
  };

  useEffect(() => {

    if (ref.current) {
      console.log('loading video');
      ref.current.load();
    } else if (ref.current) {
      console.log('video ready');
      setVideoReady(true);
    }
  }, [lang, src]);

  const onVideoEnded = () => {
    if (isNewUser) {
      setIsAuthenticated(false);
    }
  };

  return (
    <div style={{position: 'relative'}}>  
      <video
        id="fullscreen-vid"
        ref={ref}
        key={`${video.title_nl}-${lang}`}
        style={{width: '100%', objectFit: 'contain'}}
        poster={video.placeholder_image}
        controls
        onContextMenu={() => false}
        controlsList="nodownload"
        preload='none'
        onPlay={onVideoPlay}
        onLoadedData={() => setVideoReady(true)}
        onEnded={onVideoEnded}
        playsInline
      >
        <source src={src} type="video/mp4"/>
      </video>
      {/* {buttonVisible ? 
      <div style={{position: 'absolute', top: '80%', right: '5%', zIndex: 100}}>
        <Button
          text="PLAY"
          onClick={play}
          large
        />
      </div> : null} */}
    </div>
  );
};

export default FeaturedVideo;