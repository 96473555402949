import React from 'react';

interface Props {
  value: boolean;
  label: string;
  toggleValue: () => void;
  id: string;
}

const Checkbox: React.FC<Props> = ({
  value,
  label,
  toggleValue,
  id,
}) => {

  return (
    <div onClick={toggleValue} style={{paddingTop: 4, paddingBottom: 4, cursor: 'pointer'}}>
      <input type="checkbox" checked={value} onChange={toggleValue} id={id} />
      <label style={{marginLeft: 8}}>{label}</label>
    </div>
  );

};

export default Checkbox;