import React from 'react';

interface Props {
  text: string;
  onClick?: () => void;
  large?: boolean;
  disabled?: boolean;
}

const Button: React.FC<Props> = ({ text, onClick, large, disabled = false }) => {

  const onClickHandler = () => {
    if (!disabled) {
      onClick?.();
    }
  };

  return (
    <button 
      className="alamiretv-button"
      onClick={onClickHandler}
      style={{
        cursor: disabled ? 'not-allowed' : 'pointer',
        color: 'white',
        backgroundColor: '#a6361f',
        borderWidth: 0,
        boxShadow: 'none',
        fontSize: large ? '1.5rem' : '0.875rem',
        fontWeight: 'bold',
        padding: large ? '0.75rem 1.25rem' : '0.425rem 1rem',
        opacity: disabled ? 0.5 : 1,
      }}
    >
      {text}
    </button>
  );
}

export default Button;