import React from 'react';

interface Props {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  type?: string;
  autoFocus?: boolean;
  required?: boolean;
  id: string;
}

const Input: React.FC<Props> = ({
  value,
  onChange,
  placeholder,
  type,
  autoFocus,
  required,
  id,
}) => {
  return (

    <input style={{
      flex: 1,
        border: 0,
        fontSize: '0.875rem',
        color: '#454056',
      }}
      autoFocus={autoFocus}
      type={type}
      placeholder={placeholder}
      required={required}
      className="auth-input"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      id={id}
    />
  );
}

export default Input;